import {
  dateService,
  diveModeFormatter,
  postalAddressFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  PRO_BookingMemberFull,
  PRO_BookingResume,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { ClubDiverCardAttributeMedicalCertificateStatus } from 'src/business/club/modules/club-divers/components/ClubDiverCard/attributes/ClubDiverCardAttributeMedicalCertificateStatus';
import { ClubDiverCardAttributeMedicalFormStatus } from 'src/business/club/modules/club-divers/components/ClubDiverCard/attributes/ClubDiverCardAttributeMedicalFormStatus';
import { useClubSettings } from '../../../../../data/hooks';
import { DiveCenterBookingMemberDiff } from './model';

export function DiveCenterBookingMembersListDiffItemCardDetails({
  aggregatedBookingResume,
  memberDiff,
  mode,
  className,
}: {
  aggregatedBookingResume: PRO_BookingResume;
  memberDiff: DiveCenterBookingMemberDiff;
  mode: 'original' | 'final';
  className?: string;
}) {
  const { status, original, final, changes } = memberDiff;

  const memberFocus: PRO_BookingMemberFull =
    mode === 'original' ? original : final;
  const memberOther: PRO_BookingMemberFull =
    mode === 'original' ? final : original;

  const diverFocus = memberFocus?.diver;
  const diverOther = memberOther?.diver;

  const memberDetailsFocus = memberFocus?.bookingMember?.details;
  const memberDetailsOther = memberOther?.bookingMember?.details;

  const maxDateOrToday = useMemo(() => {
    return aggregatedBookingResume?.datesRange?.maxDate ?? new Date();
  }, [aggregatedBookingResume?.datesRange?.maxDate]);

  const insuranceDateBadgeStatusFocus: AppGenericStatus =
    useInsuranceDateBadgeStatus(memberFocus, maxDateOrToday);

  const insuranceFocus = memberDetailsFocus?.insurance;
  const insuranceOther = memberDetailsOther?.insurance;

  const insuranceUpdated =
    insuranceFocus?.hasInsurance !== insuranceOther?.hasInsurance ||
    insuranceFocus?.expirationDate !== insuranceOther?.expirationDate ||
    insuranceFocus?.number !== insuranceOther?.number ||
    insuranceFocus?.doc?._id !== insuranceOther?.doc?._id;

  const insuranceDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(insuranceDateBadgeStatusFocus),
    [insuranceDateBadgeStatusFocus],
  );

  const isAddressUpdated =
    diverFocus?.homeAddress?.city !== diverOther?.homeAddress?.city ||
    diverFocus?.homeAddress?.postalCode !==
      diverOther?.homeAddress?.postalCode ||
    diverFocus?.homeAddress?.line1 !== diverOther?.homeAddress?.line1 ||
    diverFocus?.homeAddress?.state !== diverOther?.homeAddress?.state ||
    diverFocus?.homeAddress?.country !== diverOther?.homeAddress?.country;

  const lastDiveDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!memberDetailsFocus?.lastDiveDate) {
      return 'error';
    }
    if (dateService.getAgeInMonths(memberDetailsFocus?.lastDiveDate) < 7) {
      return 'success';
    }
    if (dateService.getAgeInMonths(memberDetailsFocus?.lastDiveDate) < 12) {
      return 'warn';
    }
    return 'error';
  }, [memberDetailsFocus?.lastDiveDate]);

  const lastDiveDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(lastDiveDateBadgeStatus),
    [lastDiveDateBadgeStatus],
  );

  const clubSettings = useClubSettings();

  const diverDetailsFocus = diverFocus?.details;
  const diverDetailsOther = diverOther?.details;
  const bookingMemberDetailsFocus = memberFocus?.bookingMember?.details;
  const bookingMemberDetailsOther = memberOther?.bookingMember?.details;

  return (
    <div className={clsx('', className)}>
      <div
        className={`flex justify-between sm:justify-start flex-wrap gap-x-4 gap-y-2 ${className}`}
      >
        {memberDetailsFocus?.expectedDive?.diveMode && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Prestation souhaitée
            </h3>
            <p className="text-gray-600 font-bold uppercase">
              {diveModeFormatter.formatDiveMode(
                memberDetailsFocus?.expectedDive?.diveMode,
                {
                  format: 'long-label',
                  diveModesConf: clubSettings?.ui?.diveMode,
                },
              )}
            </p>
          </div>
        )}
        {(diverFocus?.medicalCertificateDate ||
          diverFocus?.medicalCertificateChecked) &&
          (diverFocus?.medicalCertificateDate !==
            diverOther?.medicalCertificateDate ||
            diverFocus?.medicalCertificateChecked !==
              diverOther?.medicalCertificateChecked) && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Certificat médical
              </h3>
              <ClubDiverCardAttributeMedicalCertificateStatus
                diver={diverFocus}
              />
            </div>
          )}
        {(bookingMemberDetailsFocus?.medicalForm?.date ||
          bookingMemberDetailsFocus?.medicalForm?.filled) &&
          (bookingMemberDetailsFocus?.medicalForm?.date !==
            bookingMemberDetailsOther?.medicalForm?.date ||
            bookingMemberDetailsFocus?.medicalForm?.filled !==
              bookingMemberDetailsOther?.medicalForm?.filled) && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Formulaire médical
              </h3>
              <ClubDiverCardAttributeMedicalFormStatus
                bookingMember={memberFocus?.bookingMember}
              />
            </div>
          )}
        {memberDetailsFocus?.lastDiveDate &&
          memberDetailsFocus?.lastDiveDate !==
            memberDetailsOther?.lastDiveDate && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Dernière plongée
              </h3>
              <div className={`${lastDiveDateBadgeStatusClass}`}>
                <div className="flex items-center">
                  <AppGenericStatusIcon
                    status={lastDiveDateBadgeStatus}
                    className={'w-4 h-4 '}
                  />
                  <div className="grow">
                    <span className="ml-1">
                      {dateService.formatUTC(
                        memberDetailsFocus?.lastDiveDate,
                        'DD/MM/YYYY',
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        {memberDetailsFocus?.totalDivesCount > 0 &&
          memberDetailsFocus?.totalDivesCount !==
            memberDetailsOther?.totalDivesCount && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                NB plongées
              </h3>
              <p className="text-gray-600 font-bold">
                {memberDetailsFocus?.totalDivesCount}
              </p>
            </div>
          )}

        {diverFocus?.emergencyContact?.trim() &&
          diverFocus?.emergencyContact !== diverOther?.emergencyContact && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Contact d'urgence
              </h3>
              <p className={'text-gray-700'}>{diverFocus?.emergencyContact}</p>
            </div>
          )}
        {isAddressUpdated &&
          diverFocus?.homeAddress &&
          diverFocus?.homeAddress !== diverOther?.homeAddress && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Adresse postale
              </h3>
              <p className={'text-gray-700'}>
                {postalAddressFormatter.format(diverFocus.homeAddress)}
              </p>
            </div>
          )}
        {insuranceUpdated &&
          (insuranceFocus?.hasInsurance === true ||
            insuranceFocus?.hasInsurance === false) && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Assurance
              </h3>
              <div className={`${insuranceDateBadgeStatusClass}`}>
                {insuranceFocus?.hasInsurance === true ? (
                  <div className="flex flex-col gap-0.5 items-start">
                    {insuranceFocus?.number && (
                      <div>{insuranceFocus?.number}</div>
                    )}
                    <div className="flex items-center">
                      {insuranceFocus?.expirationDate && (
                        <>
                          <AppGenericStatusIcon
                            status={insuranceDateBadgeStatusFocus}
                            className={'w-4 h-4 '}
                          />
                          <div className="grow">
                            <span className="ml-1">
                              {dateService.formatUTC(
                                insuranceFocus?.expirationDate,
                                'DD/MM/YYYY',
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="text-xs text-status-error">NON</div>
                )}
              </div>
            </div>
          )}
        {diverFocus?.size && diverFocus?.size !== diverOther?.size && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Taille (cm)
            </h3>
            <p className={'text-gray-700'}>{diverFocus?.size}</p>
          </div>
        )}
        {diverFocus?.weight && diverFocus?.weight !== diverOther?.weight && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Poids (Kg)
            </h3>
            <p className={'text-gray-700'}>{diverFocus?.weight}</p>
          </div>
        )}
        {diverDetailsFocus?.measurements?.shoeSize &&
          diverDetailsFocus?.measurements?.shoeSize !==
            diverDetailsOther?.measurements?.shoeSize && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Pointure
              </h3>
              <p className={'text-gray-700'}>
                {diverDetailsFocus?.measurements?.shoeSize}
              </p>
            </div>
          )}
        {diverDetailsFocus?.identificationNumber &&
          diverDetailsFocus?.identificationNumber !==
            diverDetailsOther?.identificationNumber && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                N° CNI ou Passeport
              </h3>
              <p className={'text-gray-700'}>
                {diverFocus.details?.identificationNumber}
              </p>
            </div>
          )}
        {diverDetailsFocus?.newsletter?.registrationActive &&
          diverDetailsFocus?.newsletter?.registrationActive !==
            diverDetailsOther?.newsletter?.registrationActive && (
            <div className="block text-left">
              <h3 className="text-gray-500 uppercase font-normal max-w-full">
                Newsletter
              </h3>
              <div className="text-xs text-status-success">OUI</div>
            </div>
          )}
        {bookingMemberDetailsFocus?.expectedDive?.customerComment &&
          bookingMemberDetailsFocus?.expectedDive?.customerComment !==
            memberOther?.bookingMember?.details?.expectedDive
              ?.customerComment && (
            <div className="mt-1 block text-left">
              {/* <h3 className="text-gray-500 uppercase font-normal max-w-full">
                  Commentaire
                </h3> */}
              <p className="text-red-400 font-bold italic">
                "{bookingMemberDetailsFocus?.expectedDive?.customerComment}"
              </p>
            </div>
          )}
      </div>
    </div>
  );
}
function useInsuranceDateBadgeStatus(
  memberFocus: PRO_BookingMemberFull,
  maxDateOrToday: Date,
): AppGenericStatus {
  return useMemo(() => {
    if (!memberFocus.bookingMember?.details?.insurance?.expirationDate) {
      return 'warn';
    }
    if (
      !dateService.isBefore(
        memberFocus.bookingMember?.details?.insurance?.expirationDate,
        maxDateOrToday,
      )
    ) {
      return 'success';
    }
    return 'error';
  }, [
    memberFocus.bookingMember?.details?.insurance?.expirationDate,
    maxDateOrToday,
  ]);
}
