import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  DIVE_MODE_BEGINNER,
  DIVE_MODE_WITH_CERTIFICATION,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlRHF,
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { useMediaSizeTailwind } from '../../../../../../business/_core/modules/layout';
import { CustomerSpaceConfigFormModel } from '../model';

// const CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES_OPTIONS =
//   CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES.map((value) => {
//     const option: ValueLabel<ClubSettingsUIPlanningShowPassMode> = {
//       label: (value === 'consumed'
//         ? 'consommé (8/10)'
//         : 'restant (2)'
//       ).toUpperCase(),
//       value,
//     };
//     return option;
//   });

export function CustomerSpaceConfigFormCustomerUpdate_DiversContactInfo({
  form,
  displayExpertMode,
}: {
  form: UseFormReturn<CustomerSpaceConfigFormModel>;
  displayExpertMode: boolean;
}) {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const formPrefix = 'clubSettings.publicSettings.customerSpace' as const;
  const clubSettings = useClubSettings();

  const diveModesOptionsBeginner: ValueLabel<DiveMode>[] = useMemo(
    () =>
      DIVE_MODE_BEGINNER.map((diveMode) => ({
        value: diveMode,
        label: diveModeFormatter.formatDiveMode(diveMode, {
          format: 'long-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
      })),
    [clubSettings?.ui?.diveMode],
  );

  const diveModesOptionsWithCertification: ValueLabel<DiveMode>[] = useMemo(
    () =>
      DIVE_MODE_WITH_CERTIFICATION.map((diveMode) => ({
        value: diveMode,
        label: diveModeFormatter.formatDiveMode(diveMode, {
          format: 'long-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
      })),
    [clubSettings?.ui?.diveMode],
  );

  const [diversContactInfo] = useWatch({
    control,
    name: [`${formPrefix}.defaultBookingConfig.pendingList.diversContactInfo`],
  });

  const mediaSize = useMediaSizeTailwind();
  const isSmallWidth = mediaSize === 'xxs' || mediaSize === 'xs';

  return (
    <Disclosure defaultOpen={!isSmallWidth}>
      {({ open }) => (
        <div className={clsx(open && 'bg-gray-100')}>
          <Disclosure.Button
            className={clsx(
              'flex w-full justify-between rounded-lg text-left text-sm font-medium',
              open &&
                'hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75',
            )}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <AppFormControlRHF
                className="my-1 w-full xs:col-span-6 sm:col-span-6"
                control={control}
                name={`${formPrefix}.defaultBookingConfig.pendingList.diversContactInfo`}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    {...props}
                    label={'coordonnees des participants'}
                    label2={'Nom, prénom, téléphone...'}
                    onChange={(value) => {
                      if (value) {
                        setValue(
                          `${formPrefix}.defaultBookingConfig.customerUi.enableCustomerUpdate`,
                          value,
                        );
                      }
                    }}
                  />
                )}
              />
            </div>
            {diversContactInfo && (
              <div className="flex gap-2 text-gray-600 ">
                <span>Configuration avancée</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-gray-500`}
                />
              </div>
            )}
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {diversContactInfo && (
              <div className="mb-3 w-full grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-2">
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Prénom"
                    name={`${formPrefix}.displayFields.diversContactInfo.firstName.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      // label="Requis?"
                      name={`${formPrefix}.displayFields.diversContactInfo.firstName.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    className=""
                    control={control}
                    label="NOM"
                    name={`${formPrefix}.displayFields.diversContactInfo.lastName.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.lastName.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Photo de profil"
                    name={`${formPrefix}.displayFields.diversContactInfo.profilePhoto.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.profilePhoto.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Téléphone portable"
                    name={`${formPrefix}.displayFields.diversContactInfo.phoneNumber.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.phoneNumber.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Addresse e-mail"
                    name={`${formPrefix}.displayFields.diversContactInfo.emailAddress.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.emailAddress.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Date de naissance"
                    name={`${formPrefix}.displayFields.diversContactInfo.birthdate.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.birthdate.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Langue"
                    name={`${formPrefix}.displayFields.diversContactInfo.languageCode.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.languageCode.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Contact d'urgence"
                    name={`${formPrefix}.displayFields.diversContactInfo.emergencyContact.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.emergencyContact.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>{' '}
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Taille (cm)"
                    name={`${formPrefix}.displayFields.diversContactInfo.size.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.size.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>{' '}
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Poids (Kg)"
                    name={`${formPrefix}.displayFields.diversContactInfo.weight.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.weight.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <AppFormControlRHF
                    control={control}
                    label="Pointure"
                    name={`${formPrefix}.displayFields.diversContactInfo.shoeSize.edit`}
                    renderComponent={(props) => (
                      <AppInputBooleanSwitchRHF {...props} />
                    )}
                  />
                  {displayExpertMode && (
                    <AppFormControlRHF
                      control={control}
                      name={`${formPrefix}.displayFields.diversContactInfo.shoeSize.required`}
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          type="checkbox"
                          className="-ml-2 scale-125 block mx-auto mt-1"
                          label="Obligatoire"
                          {...props}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
