/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import clsx from 'clsx';
import { useFieldArray } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { StaffMemberEditDialogLocalState } from '../useStaffMemberEditDialogLocalState.hook';
import { StaffMemberEditFormAvailabily } from './StaffMemberEditFormAvailabilities';

export const StaffMemberEditForm_Tab2_Availabilities = ({
  localState,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue } = form;

  const diveCenterResume = useDiveCenterResume();

  const { fields: availabilitiesFields } = useFieldArray({
    control,
    name: 'staffMember.availabilities',
  });

  return (
    <div className={clsx('card gap-2', className)}>
      {/* <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Disponibilités
      </h2> */}
      {availabilitiesFields.map((field, fieldIndex) => {
        return (
          <StaffMemberEditFormAvailabily
            key={field._id}
            localState={localState}
            field={field}
            fieldIndex={fieldIndex}
          />
        );
      })}
    </div>
  );
};
