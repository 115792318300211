import { useMemo } from 'react';
import { SideMenuEntry } from 'src/business/_core/modules/layout';
import { SideMenuExtraButton } from 'src/business/_core/modules/layout/components/SideMenu/SideMenuExtraButton.model';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsMaterial,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  AppSecurityUser,
  authenticationChecker,
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { clubCommunicationUrlBuilder } from '../../../../../../pages/COM-communication/clubCommunicationUrlBuilder.service';
import {
  LoadableContent,
  LoadableContentActions,
} from '../../../../../_core/data/app-loading';
import { useClubOfferState } from '../../../club-offer/ClubOfferViewPage/useClubOfferState.hook';
import {
  clubPlanningStaffUrlBuilder,
  clubPlanningUrlBuilder,
} from '../../../url-builders';
import { ClubRootSpaceNotificationsVM } from './useClubRootSpaceNotifications.hook';

export function useClubMenuEntries({
  user,
  notificationsContent,
}: {
  user: AppSecurityUser;
  notificationsContent: LoadableContent<ClubRootSpaceNotificationsVM> &
    LoadableContentActions;
}): {
  entries: SideMenuEntry[];
  extraButtons: SideMenuExtraButton[];
} {
  const clubResume = useClubResume();

  const az = useAppSecurityUserClubAuthorizations();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const clubSettings = useClubSettings();

  const clubOfferState = useClubOfferState(clubResume?.offer);

  const { isCurrentOfferOver, isPaymentDeadlineOver, isPaymentDeadlineSoon } =
    clubOfferState;

  const bookingsUpdatesCount =
    notificationsContent?.content?.bookingsUpdatesCount ?? 0;
  const onlineBookingSubmittedCount =
    notificationsContent?.content?.onlineBookingSubmittedCount ?? 0;

  return useMemo(() => {
    const entries: SideMenuEntry[] = [];
    const extraButtons: SideMenuExtraButton[] = [];

    if (authenticationChecker.hasRequiredRoles(user, ['club-view-dashboard'])) {
      entries.push({
        label: 'Dashboard',
        link: '/club/dashboard',
        heroIcon: AppHeroIcons.stats,
        // notificationStatus: 'warn',
      });
    }
    if (authenticationChecker.hasRequiredRoles(user, ['club-view-planning'])) {
      entries.push({
        label: 'Planning',
        link: clubPlanningUrlBuilder.buildPlanningUrl(),
        icon: AppIconsMaterial.calendar,
      });
    }
    if (az?.staff?.view?.staffPlanning) {
      entries.push({
        label: 'Moniteurs',
        link: clubPlanningStaffUrlBuilder.buildPlanningUrl(),
        heroIcon: AppIcons.settings.diveCenter.staff,
      });
    }
    if (
      authenticationChecker.hasRequiredRoles(user, ['club-view-lists-diver'])
    ) {
      entries.push({
        label: 'Plongeurs',
        link: '/club/lists/divers',
        icon: AppIconsMaterial.divers,
      });
    }
    if (
      authenticationChecker.hasRequiredRoles(user, [
        'club-view-lists-participant',
      ])
    ) {
      entries.push({
        label: 'Réservations',
        link: '/club/lists/bookings',
        heroIcon: EntitiesIcons.booking,
        notificationCount: bookingsUpdatesCount,
        notificationStatus: bookingsUpdatesCount > 0 ? 'danger' : undefined,
      });
    }
    if (az?.communication?.action?.sendMassMailing) {
      entries.push({
        label: 'Mailing',
        link: clubCommunicationUrlBuilder.buildMailingUrl(),
        heroIcon: AppIcons.socialMedia.mail,
      });
    }
    if (
      clubSettings?.general?.onlineBooking?.enabled &&
      clubSettings?.general?.onlineBooking?.enableProOrdersList &&
      az?.orders?.view?.ordersList
    ) {
      entries.push({
        label: 'Commandes',
        link: '/club/lists/orders',
        heroIcon: EntitiesIcons.order,
        notificationCount: onlineBookingSubmittedCount,
        notificationStatus:
          onlineBookingSubmittedCount > 0 ? 'danger' : undefined,
      });
    }
    if (authenticationChecker.hasRequiredRoles(user, ['club-view-settings'])) {
      entries.push({
        label: 'Paramètres',
        link: '/club/settings',
        icon: AppIconsMaterial.settings,
      });
    }
    if (isSuperAdmin) {
      entries.push({
        label: 'Tarifs',
        link: '/club/services/dive',
        icon: AppIconsMaterial.services,
      });
    }
    if (az?.view?.settings?.mabadiveBilling) {
      entries.push({
        label: 'Forfait',
        link: '/club/offer',
        heroIcon: AppHeroIcons.mabadiveOffer,
        notificationStatus: isPaymentDeadlineOver
          ? 'danger'
          : isPaymentDeadlineSoon
          ? 'warn'
          : isCurrentOfferOver
          ? 'warn'
          : undefined,
      });
    }
    entries.push({
      label: 'Contact',
      link: '/club/contact',
      heroIcon: AppHeroIcons.contact,
    });
    return { entries, extraButtons };
  }, [
    az?.communication?.action?.sendMassMailing,
    az?.orders?.view?.ordersList,
    az?.staff?.view?.staffPlanning,
    az?.view?.settings?.mabadiveBilling,
    bookingsUpdatesCount,
    clubSettings?.general?.onlineBooking?.enableProOrdersList,
    clubSettings?.general?.onlineBooking?.enabled,
    isCurrentOfferOver,
    isPaymentDeadlineOver,
    isPaymentDeadlineSoon,
    isSuperAdmin,
    onlineBookingSubmittedCount,
    user,
  ]);
}
