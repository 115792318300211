import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppMultiCheckboxesProps } from './AppMultiCheckboxesProps.type';

export function AppMultiCheckboxesTailwind<
  T extends string | number,
  L extends string | React.ReactNode = string,
>({
  defaultValue,
  onChange,
  options,
  buildLabel,
  direction,
  labelPosition,
  required,
  className,
}: AppMultiCheckboxesProps<T, L> & {
  buildLabel?: (label: L) => React.ReactNode;
}) {
  const [state, setState] = React.useState(defaultValue ? defaultValue : []);
  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue); // mise à jour si le formValue à changé via la méthode reset()
    }
  }, [defaultValue]);

  const toggleOption = useCallback(
    (option: ValueLabel<T, L>) => {
      const index = state.indexOf(option.value);
      if (index === -1) {
        const newState = state.concat([option.value]).filter((x) => !!x);
        newState.sort();
        setState(newState);
        onChange(newState);
      } else {
        const newState = state
          .filter((value) => value !== option.value)
          .filter((x) => !!x);
        newState.sort();
        setState(newState);
        onChange(newState);
      }
    },
    [onChange, state],
  );

  function isChecked(option: ValueLabel<T, L>) {
    return state.indexOf(option.value) !== -1;
  }

  return (
    <div
      className={clsx(
        'flex gap-2',
        direction === 'vertical' ? 'flex-col' : 'flex-wrap',
        className,
      )}
    >
      {options.map((option) => (
        <div
          key={option.value}
          className={clsx(
            'flex text-xs  font-bold cursor-pointer',
            direction === 'vertical'
              ? 'flex-row-reverse gap-2 justify-end items-start'
              : labelPosition === 'right'
              ? 'flex-row-reverse gap-0.5 lg:gap-1 items-center'
              : 'flex-col gap-0.5 items-center',
            required && state?.length === 0
              ? 'text-status-error'
              : 'text-gray-500',
          )}
          onClick={(e) => {
            e.stopPropagation();
            toggleOption(option);
          }}
        >
          <label className="cursor-pointer">
            {buildLabel ? buildLabel(option.label) : option.label}
          </label>
          <input
            className={clsx(
              'cursor-pointer',
              required &&
                state?.length === 0 &&
                'text-status-error app-form-error',
            )}
            type="checkbox"
            value={option.value}
            checked={isChecked(option)}
            onChange={(e) => {
              e.stopPropagation();
              toggleOption(option);
            }}
          />
        </div>
      ))}
    </div>
  );
}
