/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubStaffMemberDailyAvailabilities,
  ClubStaffMemberDailyAvailability,
  ClubStaffMemberProfile,
  PRO_AppDocResume,
  WEEK_DAYS_ISO,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  dateService,
  uuidGenerator,
} from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { bookingUpdateStateBuilder } from '../../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/bookingUpdateStateBuilder.service';
import {
  ClubDialogsState,
  useClubDialogs,
  useClubDialogsActionsPersist,
  UseClubDialogsProps,
} from '../../../../_dialogs';
import {
  StaffMemberEditDialog_UpdateState,
  StaffMemberEditDialogInitialState,
  StaffMemberEditFormModel,
} from './_model';
import { staffmemberAggregatedDataBuilder } from './staffmemberAggregatedDataBuilder.service';
import { useStaffMemberAttachedData } from './useStaffMemberAttachedData.hook';

export function useStaffMemberEditDialogLocalState({
  initialState,
}: {
  initialState: StaffMemberEditDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const initialFormValue: Partial<StaffMemberEditFormModel> = useMemo(() => {
    if (initialState.mode === 'create') {
      const createInitialValue: Partial<StaffMemberEditFormModel> = {
        staffMember: {
          profile: {
            roles: [
              'diving-director',
              'scuba-diving-instructor',
              'surface-security',
            ],
          } as ClubStaffMemberProfile,
          availabilities: [
            {
              _id: uuidGenerator.random(),
              beginDate: dateService.add(
                dateService.getUTCDateSetTime(new Date(), 0),
                -1,
                'week',
              ),
              defaultWorkingDays: WEEK_DAYS_ISO.reduce((acc, isoWeekDay) => {
                const av: ClubStaffMemberDailyAvailability = {
                  availabilityPeriod: 'day',
                };
                acc[isoWeekDay] = av;
                return acc;
              }, {} as ClubStaffMemberDailyAvailabilities),
              jobType: 'regular',
              endDate: undefined,
            },
          ],
        },
      };
      return createInitialValue;
    } else {
      return initialState.defaultValue;
    }
  }, [initialState.defaultValue, initialState.mode]);

  const form = useForm<StaffMemberEditFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    appDocEdit: {
      onCreateAndPersisted: ({ appDoc }: { appDoc: PRO_AppDocResume }) => {
        const docResumesChanges = changeDescriptorManager.addOneOriginal(
          appDoc,
          {
            changeDescriptors: updateState.docResumesChanges,
          },
        );
        setUpdateState({
          ...updateState,
          docResumesChanges,
        });
      },
      onUpdateNotPersisted: ({
        patches,
      }: {
        patches: AppEntityUpdatePatch[];
      }) => {
        const docResumesChanges = changeDescriptorManager.updateMany(patches, {
          optimizePatches: false,
          changeDescriptors: updateState.docResumesChanges,
        });
        setUpdateState({
          ...updateState,
          docResumesChanges,
        });
      },
      onDeleteNotPersisted: ({ appDocIds }: { appDocIds: string[] }) => {
        // TODO
        const docResumesChanges = changeDescriptorManager.deleteMany(
          appDocIds,
          {
            changeDescriptors: updateState.docResumesChanges,
          },
        );
        setUpdateState({
          ...updateState,
          docResumesChanges,
        });
      },
    },
  });

  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const emptyUpdateState = useMemo(
    () =>
      bookingUpdateStateBuilder.createEmptyUpdateState({
        hasChanges: false,
      }),
    [],
  );

  const staffMemberId = initialState.defaultValue?.staffMember?._id;

  const { data: loadedContent } = useStaffMemberAttachedData({
    staffMemberId,
  });

  const [updateState, setUpdateState] =
    useState<StaffMemberEditDialog_UpdateState>(emptyUpdateState);

  const { aggregatedData } = useMemo(
    () =>
      staffmemberAggregatedDataBuilder.aggregateAll({
        loadedContent,
        updateState,
      }),
    [loadedContent, updateState],
  );

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  const docResumes = aggregatedData?.docResumes ?? [];

  return {
    data: { docResumes },
    state: {
      mode: initialState.mode,
      staffMemberId,
      dialogsState,
      updateState,
      setUpdateState,
      form,
      hasFormChanges,
      initialState,
      // filters,
      // setFilters,
      // trainingCommercialCategoriesOptions,
      // organizationsOptions,
    },
  };
}

export type StaffMemberEditDialogLocalState = ReturnType<
  typeof useStaffMemberEditDialogLocalState
>;
