/* eslint-disable @typescript-eslint/no-unused-vars */
import { Booking, BookingCustomerUpdate } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  DiveCenterBookingMemberDiff,
  DiveCenterBookingMemberDiffChanges,
  diveCenterBookingPageAggregatedBookingResumeBuilderCore,
} from '../../../../_from-diver-app';
import { DiveCenterBookingMembersDiffList } from '../../../../_from-diver-app/DiveCenterBookingMembersList/DiveCenterBookingMembersDiffList';
import {
  BookingTabModel,
  DiverBookingPageAggregatedDataCore,
  PRO_BookingMemberFull,
  PRO_BookingMemberFull_WithDocs,
} from '../../../models';
import { diverBookingPageAggregatedBookingResumeBuilderCore } from '../../../services';

import clsx from 'clsx';
import { AppMessageLight } from '../../../../../../../../_core/modules/layout';
import { AppIconsAction } from '../../../../../../../../_core/modules/layout/icons';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates_ActionsBar } from './DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates_ActionsBar';

export const DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates = ({
  globalState,
  bookingCustomerUpdates,
  bookingTabModel,
  showTitle,
  onClickClose,
  onAcceptOrDeny,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  bookingCustomerUpdates: BookingCustomerUpdate[];
  bookingTabModel: BookingTabModel;
  showTitle?: boolean;
  onClickClose?: () => void;
  onAcceptOrDeny?: () => void;
  className?: string;
}) => {
  const booking = bookingTabModel.aggregatedBooking.booking;

  const bookingMembersFullWithAutoFix: PRO_BookingMemberFull[] =
    useBookingMembersFullWithAutoFix({
      bookingCustomerUpdates,
      booking,
      bookingTabModel,
      globalState,
    });
  const bookingMembersFull1 =
    bookingTabModel.aggregatedBooking.bookingMembersFull;

  const bookingMembersFull2 = bookingMembersFullWithAutoFix;

  const membersDiff: DiveCenterBookingMemberDiff[] = useMembersDiff({
    bookingMembersFull1,
    bookingMembersFull2,
    bookingCustomerUpdates,
  });

  return bookingCustomerUpdates.length !== 0 ? (
    <div className={clsx('relative', className)}>
      <div className="max-w-2xl md:max-w-none">
        {showTitle && (
          <div className="flex gap-8 justify-between">
            <h2 className="text-left font-bold uppercase text-app-primary">
              <AppIconsAction.refresh className="inline-flex mr-2 w-6 h-6" />
              <span>Mise à jour client</span>
            </h2>
            {onClickClose && (
              <AppIconsAction.close
                className="rounded-full size-8 cursor-pointer p-1 w-10 h-10 bg-gray-500 hover:bg-gray-800 text-gray-200 hover:text-white"
                onClick={onClickClose}
              />
            )}
          </div>
        )}
        <AppMessageLight className="text-gray-600 font-bold" hideBorder={true}>
          Le client a renseigné de nouvelles informations.
        </AppMessageLight>

        {membersDiff?.length > 3 && (
          <DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates_ActionsBar
            className="my-4"
            globalState={globalState}
            bookingCustomerUpdates={bookingCustomerUpdates}
            bookingTabModel={bookingTabModel}
            bookingMembersFullWithAutoFix={bookingMembersFullWithAutoFix}
            onAcceptOrDeny={onAcceptOrDeny}
          />
        )}

        <DiveCenterBookingMembersDiffList
          className="my-4"
          aggregatedBookingResume={bookingTabModel.aggregatedBooking}
          membersDiff={membersDiff}
        />
        <DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates_ActionsBar
          className="mt-5 lg:mt-8 "
          globalState={globalState}
          bookingCustomerUpdates={bookingCustomerUpdates}
          bookingTabModel={bookingTabModel}
          bookingMembersFullWithAutoFix={bookingMembersFullWithAutoFix}
          onAcceptOrDeny={onAcceptOrDeny}
        />
      </div>
    </div>
  ) : null;
};
function useBookingMembersFullWithAutoFix({
  globalState,
  bookingCustomerUpdates,
  booking,
  bookingTabModel,
}: {
  globalState: DiverBookingPageGlobalState;
  bookingCustomerUpdates: BookingCustomerUpdate[];
  booking: Booking;
  bookingTabModel: BookingTabModel;
}): PRO_BookingMemberFull[] {
  const clubResume = useClubResume();

  const { updateState, loadedContent } = globalState;
  return useMemo(() => {
    if (bookingCustomerUpdates?.length > 0) {
      try {
        let aggregatedDataCore: DiverBookingPageAggregatedDataCore =
          diverBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore({
            updateState,
            loadedContent,
          });

        for (const customerUpdate of bookingCustomerUpdates) {
          // apply all changes
          aggregatedDataCore =
            diveCenterBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore(
              {
                updateState: customerUpdate.payload.updateState,
                entities: aggregatedDataCore,
              },
            );
        }

        const bookingMembersFull: PRO_BookingMemberFull[] =
          aggregatedDataCore.bookingMembers
            .filter((x) => x.bookingId === booking._id)
            .map((bookingMember) => {
              const diver = aggregatedDataCore.divers.find(
                (x) => x._id === bookingMember.diverId,
              );
              const memberFull: PRO_BookingMemberFull = {
                booking,
                bookingMember,
                diver,
              };
              return memberFull;
            });

        return bookingMembersFull;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        appLogger.error(
          `[${clubResume.reference}] unable to apply auto-fix on booking ${booking._id}`,
        );
        return bookingTabModel.aggregatedBooking.bookingMembersFull;
      }
    } else {
      return bookingTabModel.aggregatedBooking.bookingMembersFull;
    }
  }, [
    booking,
    bookingCustomerUpdates,
    bookingTabModel.aggregatedBooking.bookingMembersFull,
    clubResume.reference,
    loadedContent,
    updateState,
  ]);
}

function useMembersDiff({
  bookingMembersFull1,
  bookingMembersFull2,
  bookingCustomerUpdates,
}: {
  bookingMembersFull1: PRO_BookingMemberFull_WithDocs[];
  bookingMembersFull2: PRO_BookingMemberFull[];
  bookingCustomerUpdates: BookingCustomerUpdate[];
}): DiveCenterBookingMemberDiff[] {
  return useMemo(() => {
    const results: DiveCenterBookingMemberDiff[] = bookingMembersFull1
      .map((original) => {
        const final = bookingMembersFull2.find(
          (x) => x.diver?._id === original.diver?._id,
        );

        const changes: DiveCenterBookingMemberDiffChanges = {
          diversChanges: [],
          bookingMembersChanges: [],
        };
        bookingCustomerUpdates.forEach((upd) => {
          const updateState = upd.payload.updateState;
          if (updateState) {
            changes.diversChanges = changes.diversChanges.concat(
              updateState.diversChanges.filter(
                (x) => x.pk === original.diver._id,
              ),
            );
            changes.bookingMembersChanges =
              changes.bookingMembersChanges.concat(
                updateState.bookingMembersChanges.filter(
                  (x) => x.pk === original.bookingMember._id,
                ),
              );
          }
        });
        const hasChanges =
          changes.diversChanges.length + changes.bookingMembersChanges.length >
          0;
        const memberDiff: DiveCenterBookingMemberDiff = {
          status: final && hasChanges ? 'updated' : 'not-updated',
          original,
          final,
          changes, // TODO
        };
        return memberDiff;
      })
      .filter((x) => x.status !== 'not-updated');
    return results;
  }, [bookingCustomerUpdates, bookingMembersFull1, bookingMembersFull2]);
}
